* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  min-height: 100vh;
  min-width: 100vw;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.75rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-height: 100vh;
  min-width: 100vw;
}

a,
button {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

.footer-brand {
  margin-left: 1em;
}

/* ===== START FIXING ISSUES ON MOBILE SCREEN ===== */
/* main input form */
.short-screen {
  margin-top: 3rem;
}

@media screen and (max-height: 600px) {
  .short-screen {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 375px) and (max-height: 675px) {
  .short-screen {
    margin-top: 0.75rem;
  }
}

/* "basic calculations" header */
@media screen and (max-width: 374px) and (max-height: 655px) {
  .short-screen-header {
    display: none;
  }
}

@media screen and (min-width: 1000px) and (max-height: 650px) {
  .short-screen-header {
    display: none;
  }
}

/* hide footer on mobile */
@media screen and (max-width: 420px) {
  footer {
    display: none;
  }
}
/* ===== END FIXING ISSUES ON MOBILE SCREEN ===== */